import "../libs/w2ui/w2ui.min.css";
import "../styles/app.css";
import { w2sidebar, w2layout, w2grid } from '../libs/w2ui/w2ui.es6.min.js';
import layout from '../components/layout';
import sidebar from '../components/sidebar';
import grid1 from '../components/grid1';
import grid2 from '../components/grid2';

const appLayout = new w2layout(layout);
const appSidebar = new w2sidebar(sidebar);
const people = new w2grid(grid1);
new w2grid(grid2);

// initialization
appLayout.render('#app-main');
appLayout.html('left', appSidebar);
appLayout.html('main', people);