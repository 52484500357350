import { w2ui, query } from '../libs/w2ui/w2ui.es6.min.js';

const sidebar = {
    name: 'sidebar',
    nodes: [
        { id: 'general', text: 'General', group: true, expanded: true, nodes: [
            { id: 'grid1', text: 'Grid 1', icon: 'fa fa-pencil-square-o', selected: true },
            { id: 'grid2', text: 'Grid 2', icon: 'fa fa-pencil-square-o' },
            { id: 'html', text: 'Some HTML', icon: 'fa fa-list-alt' }
        ]}
    ],
    onClick(event) {
        switch (event.target) {
            case 'grid1':
                w2ui.layout.html('main', w2ui.grid1);
                break;
            case 'grid2':
                w2ui.layout.html('main', w2ui.grid2);
                break;
            case 'html':
                w2ui.layout.html('main', '<div style="padding: 10px">Some HTML</div>');
                query(w2ui.layout.el('main'))
                    .removeClass('w2ui-grid')
                    .css({
                        'border-left': '1px solid #efefef'
                    });
                break;
        }
    }
};

export default sidebar;