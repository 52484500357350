const grid2 = {
    name: 'grid2',
    columns: [
        { field: 'state', text: 'State', size: '80px' },
        { field: 'title', text: 'Title', size: '100%' },
        { field: 'priority', text: 'Priority', size: '80px', attr: 'align="center"' }
    ],
    records: [
        { recid: 1, state: 'Open', title: 'Short title for the record', priority: 2 },
        { recid: 2, state: 'Open', title: 'Short title for the record', priority: 3 },
        { recid: 3, state: 'Closed', title: 'Short title for the record', priority: 1 }
    ]
};

export default grid2;